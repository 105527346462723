import classnames from 'classnames';
import { useField } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import Select, { components, Props } from 'react-select';
import Typography from '~components/typography/typography';
import { useTranslation } from '~i18n';
import { Check, IconJumpDown } from '~svg-components';
import stylesModule from './select.module.scss';

interface ISelectRootProps extends Props {
  creatable?: boolean;
  name: string;
  cacheOptions?: boolean;
  loadOptions?: (inputValue: string) => void;
  placeholder?: string;
  label?: string;
  labelRight?: string | React.ReactNode;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
}

interface Value {
  label: string;
  value: string;
}
const SelectRootComponent = React.forwardRef<
  any,
  ISelectRootProps & React.SelectHTMLAttributes<HTMLSelectElement>
>((props, ref) => {
  const {
    label,
    labelRight,
    name,
    selectType,
    loadOptions,
    className,
    inputClassName,
    labelClassName,
    options,
    cacheOptions,
    isDisabled,
    styles,
    ...others
  } = props;
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(name);
  const [arrowDown, setArrowDown] = useState(false);

  const [expandCountry, setExpandCountry] = useState(null);

  let selectRef = useRef<any>(null);
  const customStyles = {
    singleValue: (provided: any, state: any) => {
      return { ...provided, fontSize: 14, color: 'var(--color-purple_6)' };
    },
    container: (provided: any, state: { isFocused: any }) => {
      return { ...provided, marginTop: '5px', width: '100%', marginBottom: '4px' };
    },
    control: (provided: any, state: { isFocused: any }) => {
      let borderColor = state.isFocused ? 'var(--color-purple_6)' : 'var(--color-purple_4)';
      let boxShadow = state.isFocused
        ? '-2px -2px 4px rgba(64, 46, 82, 0.2), 2px 2px 4px rgba(64, 46, 82, 0.2)'
        : 'none';
      let hover = {
        ':hover': {
          backgroundColor: 'var(--color-gray_3)',
          borderColor: 'var(--color-outline)',
        },
      };
      let backgroundColor = 'none';

      if (meta.error && meta.touched) {
        borderColor = 'var(--color-red_6)';
        boxShadow = '-2px -2px 4px rgba(140, 46, 41, 0.2), 2px 2px 4px rgba(140, 46, 41, 0.2)';
      }
      return { ...provided, borderColor, boxShadow, backgroundColor, ...hover };
    },
    input: (provided: any, state: { isFocused: any }) => {
      return {
        ...provided,
        fontSize: 14,
        lineHeight: '24px',
        color: 'var(--color-purple_6)',
        margin: 0,
        padding: '12px 0',
      };
    },
    indicatorSeparator: () => {
      return {};
    },
    valueContainer: (provided: any) => {
      return { ...provided, padding: '0 10px' };
    },
    placeholder: (provided: any) => {
      return {
        ...provided,
        color: 'var(--color-purple_3)',
        fontSize: 14,
      };
    },
    option: (provided: any) => ({
      ...provided,
      color: 'var(--color-purple_6)',
      fontSize: 14,
      lineHeight: '24px',
      padding: 0,
      backgroundColor: 'var(--color-white)',
      ':hover': {
        backgroundColor: 'var(--color-purple_1)',
      },
      cursor: 'pointer',
      borderBottom: `1px solid var(--color-outline)`,
    }),
    ...styles,
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <div
          className={classnames(stylesModule.dropdownIcon, {
            [stylesModule.dropdownIconFocus]: arrowDown,
          })}
        >
          <IconJumpDown width="8" height="6" fill="var(--color-purple_4)" />
        </div>
      </components.DropdownIndicator>
    );
  };

  const GroupHeading = (props: any) => {
    return (
      <components.GroupHeading {...props}>
        <div
          onClick={() => {
            if (expandCountry === props.children) setExpandCountry(null);
            if (expandCountry !== props.children) setExpandCountry(props.children);
          }}
          className={classnames(stylesModule.groupHeadContainer, {
            [stylesModule.groupHeadContainerActive]: expandCountry === props.children,
          })}
        >
          <Typography variant="body2" component="div" style={{ display: 'flex', flex: 1 }}>
            {props.children}
          </Typography>
          <div
            className={classnames(stylesModule.dropdownIcon, {
              [stylesModule.dropdownIconFocus]: expandCountry === props.children,
            })}
          >
            <IconJumpDown width="8" height="6" fill="var(--color-grey_99)" />
          </div>
        </div>
      </components.GroupHeading>
    );
  };

  const Option = (props: any) => {
    if (!expandCountry || expandCountry !== props.data.countryValue) {
      return null;
    }

    return (
      <components.Option {...props}>
        <div className="option-container" style={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ flex: 1, padding: '12px 20px' }} variant="body3" component="div">
            {props.label}{' '}
            <Typography variant="body3" color="var(--color-purple_4)">
              ({props.data.cityValue})
            </Typography>
          </Typography>
          {props.isSelected && (
            <Check style={{ marginRight: 20 }} width="16" height="16" fill="var(--color-grey_99)" />
          )}
        </div>
      </components.Option>
    );
  };

  useEffect(() => {
    if (process.browser && !isDisabled) {
      if (selectRef?.current.select.inputRef) {
        selectRef?.current?.select?.inputRef?.setAttribute('name', name);
      } else if (selectRef?.current?.select?.select?.select?.inputRef) {
        selectRef?.current.select.select.select.inputRef?.setAttribute('name', name);
      }
    }
  }, [name, isDisabled]);

  const selectCustomProps = {
    inputRef: ref,
    styles: customStyles,
    components: {
      DropdownIndicator,
      GroupHeading,
      Option,
    },
    onMenuOpen: () => {
      setArrowDown(true);
    },
    onMenuClose: () => {
      setArrowDown(false);
    },
    isDisabled,
    theme: (theme: any) => ({
      ...theme,
      borderRadius: 14,
      colors: {
        ...theme.colors,
        primary25: 'var(--color-purple_1)',
        primary: 'var(--color-purple_6)',
        neutral90: 'var(--color-outline)',
      },
    }),
    ...others,
    name: field.name,
  };

  return (
    <div className={className}>
      <div className={stylesModule.labelContainer}>
        {label && (
          <label className={classnames(stylesModule.label, labelClassName)} htmlFor={name}>
            {label}
          </label>
        )}
        <span className={stylesModule.labelRight}>{labelRight}</span>
      </div>

      <div className={stylesModule.inputContainer}>
        <Select
          {...selectCustomProps}
          options={options}
          onChange={(option): void => {
            helpers.setValue(option);
          }}
          classNamePrefix="select-root-prefix"
          ref={selectRef}
          noOptionsMessage={() => t('form.select.no_result')}
          value={field.value}
          onBlur={() => {
            setTimeout(() => {
              helpers.setTouched(true);
            }, 100);
          }}
        />
      </div>

      {meta.touched && meta.error ? (
        <Typography variant="body3" color="var(--color-red_6)">
          {meta.error}
        </Typography>
      ) : null}
    </div>
  );
});
export default SelectRootComponent;
