import classnames from 'classnames';
import { useField } from 'formik';
import React, { useState } from 'react';
import Select, { components, Props } from 'react-select';
import Typography from '~components/typography/typography';
import { IconJumpDown } from '~svg-components';
import styles from './phone-input.module.scss';

interface InputProps extends Props {
  codeName: string;
  name: string;
  placeholder?: string;
  label?: string;
  labelRight?: string | React.ReactNode;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
}

const Input = React.forwardRef<any, InputProps & React.InputHTMLAttributes<HTMLInputElement>>(
  (props, ref) => {
    const {
      codeName,
      label,
      labelRight,
      name,
      prefix,
      className,
      inputClassName,
      labelClassName,
      placeholder,
      options,
      ...others
    } = props;
    const [field, meta] = useField(name);
    const [codeField, , codeHelpers] = useField(codeName);
    const [arrowDown, setArrowDown] = useState(false);

    const DropdownIndicator = (props: any) => {
      return (
        <components.DropdownIndicator {...props}>
          <div
            className={classnames(styles.dropdownIcon, { [styles.dropdownIconFocus]: arrowDown })}
          >
            <IconJumpDown width="8" height="6" fill="var(--color-grey_99)" />
          </div>
        </components.DropdownIndicator>
      );
    };

    const customStyles = {
      singleValue: (provided: any, state: any) => {
        return { ...provided, fontSize: 14, color: 'var(--color-purple_6)' };
      },
      container: (provided: any, state: { isFocused: any }) => {
        return {
          ...provided,
          width: 100,
        };
      },
      control: (provided: any, state: { isFocused: any }) => {
        return {
          ...provided,
          border: 0,
          borderRight: `1px solid var(--color-outline) `,
          height: '100%',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderColor: 'var(--color-outline)',
          boxShadow: 'none',
          backgroundColor: 'none',
          ':hover': {
            backgroundColor: 'var(--color-grey_3)',
            borderColor: 'var(--color-outline)',
          },
        };
      },
      input: (provided: any, state: { isFocused: any }) => {
        return {
          ...provided,
          fontSize: 14,
          lineHeight: '24px',
          color: 'var(--color-purple_6)',
          margin: 0,
          padding: '12px 0',
        };
      },
      indicatorSeparator: () => {
        return {};
      },
      valueContainer: (provided: any) => {
        return { ...provided, padding: '0 10px' };
      },
      placeholder: (provided: any) => {
        return {
          ...provided,
          color: 'var(--color-purple_4)',
          fontSize: 14,
        };
      },
      option: (provided: any, state: any) => ({
        ...provided,
        fontWeight: state.isSelected ? 600 : 400,
        color: 'var(--color-purple_6)',
        background: state.isFocused ? 'var(--color-purple_1)' : 'var(--color-white)',
        fontSize: 14,
        lineHeight: '24px',
        padding: 12,
        ':active': {
          backgroundColor: 'var(--color-purple_1)',
        },
      }),
    };
    return (
      <div className={className}>
        <div className={styles.labelContainer}>
          {label && (
            <label className={classnames(styles.label, labelClassName)} htmlFor={name}>
              {label}
            </label>
          )}
          <span className={styles.labelRight}>{labelRight}</span>
        </div>
        <div
          className={classnames(styles.inputContainer, {
            [styles.inputContainerError]: meta.error && meta.touched,
          })}
        >
          <Select
            options={options}
            inputRef={ref}
            selectType="custom"
            styles={customStyles}
            components={{ DropdownIndicator }}
            onMenuOpen={() => {
              setArrowDown(true);
            }}
            onMenuClose={() => {
              setArrowDown(false);
            }}
            classNamePrefix="phonecode-select"
            theme={theme => ({
              ...theme,
              borderRadius: 14,
              colors: {
                ...theme.colors,
                primary25: 'var(--color-purple_1)',
                primary: 'var(--color-purple_6)',
                neutral90: 'var(--color-outline)',
              },
            })}
            {...others}
            name={codeField.name}
            value={codeField.value}
            onChange={(option): void => {
              codeHelpers.setValue(option);
            }}
            onBlur={(e: any) => {
              e.target.name = name;
              codeField.onBlur(e);
            }}
          />
          <input
            className={classnames(
              styles.input,
              { [styles.inputError]: meta.touched && meta.error },
              inputClassName
            )}
            ref={ref}
            placeholder={placeholder}
            {...field}
          />
        </div>
        {meta.touched && meta.error ? (
          <Typography variant="body3" color="var(--color-red_6)">
            {meta.error}
          </Typography>
        ) : null}
      </div>
    );
  }
);

export default Input;
