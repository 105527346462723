import classnames from 'classnames';
import { useField } from 'formik';
import React from 'react';
import Typography from '~components/typography/typography';
import styles from './input.module.scss';
interface InputProps {
  name: string;
  type?: string;
  placeholder?: string;
  label?: string;
  labelRight?: string | React.ReactNode;
  className?: string;
  error?: string;
  inputClassName?: string;
  labelClassName?: string;
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  errorComponent?: React.ReactNode;
}

const Input = React.forwardRef<any, InputProps & React.InputHTMLAttributes<HTMLInputElement>>(
  (props, ref) => {
    const {
      label,
      labelRight,
      name,
      prefix,
      suffix,
      className,
      inputClassName,
      labelClassName,
      errorComponent,
      ...others
    } = props;
    const [field, meta] = useField(name);
    return (
      <div className={className}>
        <div className={styles.labelContainer}>
          {label && (
            <label className={classnames(styles.label, labelClassName)} htmlFor={name}>
              {label}
            </label>
          )}
          <span className={styles.labelRight}>{labelRight}</span>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputPrefix}>{prefix}</div>
          <input
            className={classnames(
              styles.input,
              {
                [styles.inputHasPrefix]: !!prefix,
              },
              {
                [styles.inputHasSuffix]: !!suffix,
              },
              {
                [styles.inputError]: meta.touched && meta.error,
              },
              inputClassName
            )}
            ref={ref}
            {...others}
            {...field}
          />
          <div className={styles.inputSuffix}>{suffix}</div>
        </div>
        {meta.touched && meta.error && (
          <Typography variant="body3" color="var(--color-red_6)">
            {errorComponent || meta.error}
          </Typography>
        )}
      </div>
    );
  }
);

export default Input;
