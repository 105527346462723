import { UAContext } from '@quentin-sommer/react-useragent';
import Tippy from '@tippy.js/react';
import classnames from 'classnames';
import { useField } from 'formik';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import DayPicker, { DateUtils, DayPickerProps } from 'react-day-picker';
import Button from '~components/button';
import Divider from '~components/divider';
import Typography from '~components/typography/typography';
import { useTranslation } from '~i18n';
import { IconDatepicker } from '~svg-components';

interface IFormikRangeDayPicketProps {
  name: string;
  placeholder?: string;
  label?: React.ReactNode;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  disabledDays?: DayPickerProps['disabledDays'];
}

const FormikRangeDayPicket: React.FC<IFormikRangeDayPicketProps> = props => {
  const { label, name, className, labelClassName, placeholder, disabledDays } = props;
  const { t } = useTranslation(['student_portal']);
  const uaResults = useContext(UAContext).uaResults as UAResults;
  const isMobile = uaResults.mobile;
  const [field, meta, helpers] = useField(name);
  const birthdayInputRef = useRef<any>(null);
  const [pickerVisible, setPickerVisible] = useState(false);
  const [tippyInstance, setTippyInstance] = useState<any>(null);

  const [date, setDate] = useState<{ from: Date | undefined; to: Date | undefined }>({
    from: undefined,
    to: undefined,
  });

  useEffect(() => {
    if (birthdayInputRef) {
      birthdayInputRef.current?.setAttribute('name', name);
    }
  }, [name, birthdayInputRef]);

  const handleDayClick = (day: any) => {
    const range = DateUtils.addDayToRange(day, {
      from: date.from,
      to: date.to,
    });

    if (range.from && range.to) {
      helpers.setValue(
        `${moment(range?.from).format('ddd, ll')} - ${moment(range?.to).format('ddd, ll')}`
      );
    }

    setDate({
      from: range?.from || undefined,
      to: range?.to || undefined,
    });
  };

  const handleHideTippy = useCallback(
    (e: any) => {
      if (!birthdayInputRef?.current?.contains(e.target)) {
        tippyInstance.hide();
      }
    },
    [tippyInstance]
  );

  const handleResetClick = () => {
    setDate({
      from: undefined,
      to: undefined,
    });
    helpers.setValue('');
  };

  useEffect(() => {
    document.addEventListener('click', handleHideTippy);
    return () => {
      document.removeEventListener('click', handleHideTippy);
    };
  }, [handleHideTippy]);

  const modifiers = { start: date.from, end: date.to };

  return (
    <div className={classnames('root', className)} ref={birthdayInputRef}>
      <div className="label-container">
        {label && (
          <label className={classnames('label', labelClassName)} htmlFor={name}>
            {label}
          </label>
        )}
      </div>
      <Tippy
        theme="white"
        onCreate={instance => setTippyInstance(instance)}
        content={
          <div className="range-date-picket-wrapper">
            <DayPicker
              className="range-date-picket"
              numberOfMonths={2}
              selectedDays={{ from: date.from, to: date.to }}
              modifiers={modifiers}
              onDayClick={handleDayClick}
              disabledDays={disabledDays}
            />
            {!isMobile && (
              <Typography
                variant="body3"
                className="range-date-picket-reset"
                onClick={handleResetClick}
                textDecoration="underline"
                color="var(--color-green_7)"
              >
                {t('select_bed.reset_date')}
              </Typography>
            )}
            {isMobile && (
              <>
                <Divider />
                <div className="range-date-picket-confirm">
                  <Typography
                    variant="body3"
                    textDecoration="underline"
                    color="var(--color-green_7)"
                    onClick={handleResetClick}
                  >
                    {t('select_bed.reset_date')}
                  </Typography>
                  <Button onClick={() => tippyInstance.hide()}>{t('Confirm')}</Button>
                </div>
              </>
            )}
          </div>
        }
        appendTo={e => e}
        animation="scale"
        trigger="click"
        onShow={() => setPickerVisible(true)}
        onHide={() => setPickerVisible(false)}
        maxWidth={'100%'}
        placement="bottom-start"
        hideOnClick={'toggle'}
        visible={pickerVisible}
        className="range-date-picket__tippy"
      >
        <div className="input-container" onClick={() => setPickerVisible(!pickerVisible)}>
          {field?.value || placeholder}
          <IconDatepicker
            width="14.7"
            height="15.7"
            fill="var(--color-purple_4)"
            cursor="pointer"
            className="input-container__icon"
          />
        </div>
      </Tippy>

      {meta.touched && meta.error ? (
        <Typography variant="body3" color="var(--color-red_6)">
          {meta.error}
        </Typography>
      ) : null}
      <style jsx>{`
        .range-date-picket-wrapper {
          position: relative;
        }
        .label-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .label {
          font-size: 14px;
          line-height: 24px;
          color: var(--color-purple_6);
          font-weight: 600;
        }
        .input-container {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-top: 12px;
          margin-bottom: 4px;
          padding: 12px;
          font-size: 14px;
          line-height: 24px;
          border-radius: 14px;
          min-height: 50px;
          color: ${field.value ? 'var(--color-purple_6)' : 'var(--color-purple_3)'};
          border: 1px solid
            ${meta.touched && meta.error ? 'var(--color-red_6)' : 'var(--color-purple_4)'};
          box-shadow: ${meta.touched && meta.error
            ? '-2px -2px 4px rgba(140, 46, 41, 0.2), 2px 2px 4px rgba(140, 46, 41, 0.2)'
            : 'none'};
          &:hover {
            background: var(--color-gray_3);
          }
          :global(&__icon) {
            position: absolute;
            top: 16px;
            right: 12px;
          }
          :global(.tippy-content) {
            box-shadow: 0 2px 16px 0 rgba(51, 53, 50, 0.08);
            border-radius: 14px;
          }
        }
      `}</style>
      <style jsx global>{`
        .range-date-picket-reset {
          position: absolute;
          bottom: 8px;
          right: 16px;
          cursor: pointer;
        }
        .range-date-picket-confirm {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 20px;
        }

        .range-date-picket .DayPicker-Months {
          width: auto;
        }
        .range-date-picket__tippy {
          max-width: 100% !important;
        }
        .range-date-picket__tippy .DayPicker-Day {
          border-radius: 0;
          font-size: 14px;
        }

        .range-date-picket .DayPicker-Day--start[aria-selected='true'] {
          background: var(--color-purple_6) !important;
          border-top-left-radius: 50% !important;
          color: var(--color-white) !important;
          border-bottom-left-radius: 50% !important;
        }

        .range-date-picket .DayPicker-Day--end[aria-selected='true'] {
          background: var(--color-purple_6) !important;
          border-top-right-radius: 50% !important;
          color: var(--color-white) !important;
          border-bottom-right-radius: 50% !important;
        }
        .range-date-picket
          .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
          background: var(--color-purple_1) !important;
          color: var(--color-purple_6);
        }
        .range-date-picket .DayPicker-Caption > div {
          font-size: 14px;
          color: var(--color-purple_6);
          font-family: 'UniversalSans';
        }
        .range-date-picket .DayPicker-wrapper {
          padding-bottom: 24px;
        }
        .range-date-picket .DayPicker-Caption {
          text-align: center;
        }
        .range-date-picket .DayPicker-NavButton--next {
          top: 0.9em;
        }
        .range-date-picket .DayPicker-NavButton--prev {
          top: 0.9em;
          right: unset;
          margin-right: 0;
          margin-left: 1em;
        }
        .DayPicker:not(.DayPicker--interactionDisabled)
          .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
          background: var(--color-purple_6) !important;
          color: var(--color-white) !important;
          border-radius: 50%;
        }
      `}</style>
    </div>
  );
};

export default FormikRangeDayPicket;
