const fontWeight = {
  normal: 400,
  demiBold: 500,
  semiBold: 600,
};

const colors = {
  tundora: '#4c4c4c',
  blue: '#2940eb',
  grey4c: '#3F2D51',
  grey99: '#999999',
  greyB9: '#B9B9B9',
  greyCc: '#cccccc',
  greyEb: '#ebebeb',
  greyF7: '#f7f7f7',
  greyFa: '#fafafa',
  greyText: '#859094',
  outline: '#c5c0ca',
  background: '#F5F6F7',
  yellow: '#3F2D51',
  yellowMedium: '#8C8197',
  yellowBg: '#ECEAEE',
  yellowDark: '#2C1F39',
  red: '#FE544A',
  redMedium: '#FE9892',
  redBg: '#FDEEED',
  redDark: '#B23B34',
  green: '#4FE0BE',
  greenMedium: '#95ECD8',
  greenDark: '#379D85',
  greenBg: '#EDFCF8',
  violet: '#475e9f',
  violetMedium: '#8e9fcc',
  violetBg: '#eaedf6',
  violetDark: '#3d4f7f',
  orange: '#FFC543',
  orangeBg: '#FEF8EC',
  orangeMedium: '#FFDC8E',
  orangeDark: '#B38A2F',
  orangeDarker: '#F39400',
  transparent: 'transparent',
  white: '#ffffff',
  black3B: '#3b3b3b',
  // new colors for YUGO
  purple_2: '#D9D5DC',
  purple_3: '#B2ABB9',
  purple_5: '#655774',
  purple_7: '#362645',
  purple_9: '#23192D',
  purple_10: '#191220',

  orange_2: '#FFF3D9',
  orange_3: '#FFE8B4',
  orange_5: '#FFD169',
  orange_7: '#D9A739',
  orange_9: '#8C6C25',
  orange_10: '#664F1B',

  red_2: '#FFDDDB',
  red_3: '#FFBBB7',
  red_5: '#FE6E65',
  red_7: '#D8473F',
  red_9: '#8C2E29',
  red_10: '#66221E',

  green_2: '#DCF9F2',
  green_3: '#B9F3E5',
  green_5: '#72E6CB',
  green_7: '#43BEA1',
  green_9: '#2B7B68',
  green_10: '#205A4C',

  pink_1: '#FDF3F5',
  pink_2: '#FFDDE1',
  pink_3: '#FFC5CD',
  pink_4: '#FFAFBA',
  pink_5: '#FF9DAB',
  pink_6: '#FF8C9C',
  pink_7: '#E47D8B',
  pink_8: '#C86D7A',
  pink_9: '#AD5E69',
  pink_10: '#924E58',

  blue_1: '#EAECFD',
  blue_2: '#D4D9FB',
  blue_3: '#AAB3F7',
  blue_4: '#7F8DF2',
  blue_5: '#5567EE',
  blue_6: '#2A41EA',
  blue_7: '#2437C7',
  blue_8: '#1D2DA4',
  blue_9: '#172481',
  blue_10: '#111A5E',

  gray_3: '#f5f5f5',
  gray_4: '#f0f0f0',
  gray_5: '#d9d9d9',
  gray_6: '#bfbfbf',
  gray_7: '#8c8c8c',
  gray_8: '#595959',
  gray_9: '#434343',
  gray_10: '#262626',
  gray_11: '#1f1f1f',
  gray_12: '#141414',
  gray_13: '#000000',
};

const typography = {
  h1: `
    font-size: 46px;
    font-weight: ${fontWeight.semiBold};
    line-height: 48px;
    color: ${colors.yellow};
    letter-spacing: normal;
    font-family: 'UniversalSans';
  `,
  h2: `
    font-size: 42px;
    color: ${colors.yellow};
    font-weight: ${fontWeight.semiBold};
    line-height: 44px;
    font-family: 'UniversalSans';
  `,
  h3: `
    font-size: 38px;
    font-weight: ${fontWeight.semiBold};
    color: ${colors.yellow};
    line-height: 40px;
    font-family: 'UniversalSans';
  `,
  h4: `
    font-size: 32px;
    font-weight: ${fontWeight.semiBold};
    color: ${colors.yellow};
    line-height: 36px;
    font-family: 'UniversalSans';
  `,
  h5: `
    font-size: 22px;
    font-weight: ${fontWeight.semiBold};
    color: ${colors.yellow};
    line-height: 24px;
      font-family: 'UniversalSans';
  `,
  h6: `
  font-size: 26px;
  font-weight: ${fontWeight.semiBold};
  color: ${colors.yellow};
  line-height: 30px;
    font-family: 'UniversalSans';
`,
  h7: `
  font-size: 20px;
  font-weight: ${fontWeight.semiBold};
  color: ${colors.yellow};
  line-height: 24px;
    font-family: 'UniversalSans';
`,
  h8: `
  font-size: 16px;
  font-weight: ${fontWeight.semiBold};
  color: ${colors.yellow};
  line-height: 24px;
  font-family: 'UniversalSans';
`,
  h9: `
  font-size: 14px;
  font-weight: ${fontWeight.semiBold};
  color: ${colors.yellow};
  line-height: 24px;
  font-family: 'UniversalSans';
`,
  h12: `
  font-size: 12px;
  font-weight: ${fontWeight.semiBold};
  color: ${colors.yellow};
  line-height: 20px;
  font-family: 'UniversalSans';
  `,
  h18: `
  font-size: 18px;
  font-weight: ${fontWeight.semiBold};
  color: ${colors.yellow};
  line-height: 24px;
  font-family: 'UniversalSans';
`,
  body1: `
    font-size: 18px;
    line-height: 26px;
    color: ${colors.yellow};
  `,
  body2: `
    font-size: 16px;
    line-height: 24px;
    color: ${colors.yellow};
  `,
  body3: `
    font-size: 14px;
    line-height: 24px;
    color: ${colors.yellow};
  `,
  meta: `
    font-size: 12px;
    line-height: 16px;
    color: ${colors.yellow};
  `,
  label: `
    font-size: 11px;
    line-height: 1.45;
    color: ${colors.yellow};
  `,
};

const theme = {
  fontTypes: {
    fontAvenirNext: "'Avenir Next W04', 'Avenir Next', Helvetica, Arial, sans-serif",
    fontSystem: 'Helvetica, Arial, sans-serif',
    fontLatoitalic: 'latoitalic',
    fontUniversalSans: 'UniversalSans',
    fontCn:
      'Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei UI, Microsoft YaHei, Microsoft JhengHei, sans-serif',
    fontCnTraditional: "Helvetica, Arial, 'Hiragino Sans GB', 'Microsoft JhengHei', sans-serif",
  },
  zIndex: {
    negative: -1,
    zero: 0,
    base: 1,
    low: 10,
    mid: 50,
    big: 100,
    high: 1000,
    onTop: 5000,
    onMiddle: 9998,
    topSwap: 10000,
    dropdown: 50000,
    header: 100000,
    overlay: 1000000,
    modal: 10000000,
    aboveModal: 50000000,
    king: 100000000,
  },
  inputBoxShadow: {
    focus: `-2px -2px 4px rgba(64, 46, 82, 0.2), 2px 2px 4px rgba(64, 46, 82, 0.2)`,
    error: '-2px -2px 4px rgba(140, 46, 41, 0.2), 2px 2px 4px rgba(140, 46, 41, 0.2)',
  },
  fontWeight,
  colors,
  typography,
  baseWidth: '1128px',
  bookingProcessHeight: 152,
  bookingProcessMobileHeight: 80,
};

export default theme;
