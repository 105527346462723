import classnames from 'classnames';
import { useField } from 'formik';
import React from 'react';
import Typography from '~components/typography/typography';

interface TextareaProps {
  name: string;
  type?: string;
  placeholder?: string;
  label?: string;
  labelRight?: string | React.ReactNode;
  className?: string;
  error?: string;
  inputClassName?: string;
  labelClassName?: string;
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  errorComponent?: React.ReactNode;
}

const Textarea = React.forwardRef<
  any,
  TextareaProps & React.InputHTMLAttributes<HTMLTextAreaElement>
>((props, ref) => {
  const {
    label,
    labelRight,
    name,
    prefix,
    suffix,
    className,
    inputClassName,
    labelClassName,
    errorComponent,
    ...others
  } = props;

  const [field, meta] = useField(name);
  return (
    <div className={classnames('root', className)}>
      <div className="label-container">
        {label && (
          <label className={classnames('label', labelClassName)} htmlFor={name}>
            {label}
          </label>
        )}
        <span className="label-right">{labelRight}</span>
      </div>
      <div className="input-container">
        <div className="input-prefix">{prefix}</div>
        <textarea
          className={classnames(
            'input',
            { 'input-error': meta.touched && meta.error },
            inputClassName
          )}
          ref={ref}
          {...others}
          {...field}
        />

        <div className="input-suffix">{suffix}</div>
        {others?.maxLength && (
          <Typography
            variant="body3"
            component="div"
            color="var(--color-greyText)"
            className="textarea__textarea-length"
          >
            {field?.value?.length || 0}/{others?.maxLength}
          </Typography>
        )}
      </div>
      {meta.touched && meta.error ? (
        <Typography variant="body3" color="var(--color-red_6)">
          {errorComponent || meta.error}
        </Typography>
      ) : null}
      <style jsx>{`
        :global(.textarea__textarea-length) {
          position: absolute;
          right: 8px;
          bottom: 10px;
        }
        .root {
          margin-top: 16px;
        }
        .label-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .label {
          font-size: 14px;
          line-height: 24px;
          font-weight: bold;
          color: var(--color-purple_6);
        }
        .label-right {
          cursor: pointer;
        }
        .input-container {
          position: relative;
        }
        .input-prefix {
          position: absolute;
          top: 18px;
          left: 12px;
          line-height: 24px;
        }
        .input-suffix {
          position: absolute;
          top: 18px;
          right: 12px;
          line-height: 24px;
          cursor: pointer;
        }
        .input {
          margin-top: 12px;
          margin-bottom: 4px;
          width: 100%;
          padding: 12px;
          padding-left: ${!!prefix ? 36 : 12}px;
          padding-right: ${!!suffix ? 36 : 12}px;
          font-size: 14px;
          line-height: 24px;
          border-radius: 14px;
          color: var(--color-purple_6);
          outline: none;
          border: 1px solid var(--color-purple_4);
          background: none;
          font-family: 'UniversalSans';

          &:hover {
            background: var(--color-gray_3) !important;
          }

          &:focus {
            border-color: var(--color-purple_6);
            box-shadow: -2px -2px 4px rgba(64, 46, 82, 0.2), 2px 2px 4px rgba(64, 46, 82, 0.2) !important;
          }

          &::placeholder {
            color: var(--color-purple_3);
            font-size: 14px;
          }

          &.input-error {
            box-shadow: -2px -2px 4px rgba(140, 46, 41, 0.2), 2px 2px 4px rgba(140, 46, 41, 0.2) !important;
            border-color: var(--color-red_6);
          }
        }
      `}</style>
    </div>
  );
});

export default Textarea;
