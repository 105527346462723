import classnames from 'classnames';
import { useField } from 'formik';
import React from 'react';
import styles from './checkbox.module.scss';

interface CheckboxProps {
  name: string;
  disabled?: boolean;
  label?: string | React.ReactNode;
  value?: string | boolean;
  className?: string;
  error?: string;
  labelClassName?: string;
  checkboxClassName?: string;
}

const Checkbox = React.forwardRef<any, CheckboxProps & React.InputHTMLAttributes<HTMLInputElement>>(
  (props, ref) => {
    const { label, className, checkboxClassName, labelClassName, value, ...others } = props;
    const [field, meta, helpers] = useField(props);
    const checked = () => {
      if (Array.isArray(field.value)) {
        return field.value.includes(value);
      } else {
        return !!field.value;
      }
    };
    return (
      <div className={classnames(styles.root, className)}>
        <div
          className={classnames(styles.container, {
            [styles.containerDisabled]: others.disabled,
          })}
        >
          <input
            checked={checked()}
            className={styles.input}
            type="checkbox"
            ref={ref}
            onClick={() => {
              helpers.setTouched(true);
            }}
            {...field}
            {...others}
            value={value}
          />
          <span
            className={classnames(
              styles.custom,
              {
                [styles.customDisabled]: others.disabled,
              },
              checkboxClassName
            )}
          ></span>
        </div>
        <span
          className={classnames(
            styles.label,
            {
              [styles.labelDisabled]: others.disabled,
            },
            labelClassName
          )}
        >
          {label}
        </span>
      </div>
    );
  }
);

export default Checkbox;
