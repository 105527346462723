import Tippy from '@tippy.js/react';
import classnames from 'classnames';
import { useField } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { DayPickerProps } from 'react-day-picker';
import { FixedDayPicker } from '~components/datepicker';
import Typography from '~components/typography/typography';
import { IconDatepicker } from '~svg-components';

interface PickerProps {
  name: string;
  placeholder?: string;
  label?: React.ReactNode;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  fromMonth: Date;
  toMonth: Date;
  disabledDays?: DayPickerProps['disabledDays'];
  onSelectDate?: (value: Date) => void;
}

const DayPicker = React.forwardRef<
  HTMLDivElement,
  PickerProps & React.HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  const {
    label,
    name,
    className,
    labelClassName,
    placeholder,
    fromMonth,
    toMonth,
    disabledDays,
    onSelectDate,
  } = props;
  const [field, meta, helpers] = useField(name);
  const birthdayInputRef = useRef<any>(null);
  const [pickerVisible, setPickerVisible] = useState(false);
  useEffect(() => {
    if (birthdayInputRef) {
      birthdayInputRef.current?.setAttribute('name', name);
    }
  }, [name, birthdayInputRef]); // for error focus
  return (
    <div className={classnames('root', className)} ref={birthdayInputRef}>
      <div className="label-container">
        {label && (
          <label className={classnames('label', labelClassName)} htmlFor={name}>
            {label}
          </label>
        )}
      </div>
      <Tippy
        theme="white"
        content={
          <FixedDayPicker
            fromMonthProps={fromMonth}
            toMonthProps={toMonth}
            disabledDays={disabledDays}
            onSelectDate={date => {
              if (onSelectDate) {
                onSelectDate(date);
              }
              helpers.setValue(moment(date).format('YYYY-MM-DD'));
              setPickerVisible(false);
            }}
          />
        }
        appendTo={e => e}
        animation="scale"
        trigger="click"
        onShow={() => {
          setPickerVisible(true);
        }}
        onHide={() => {
          setPickerVisible(false);
        }}
        maxWidth={280}
        placement="bottom-start"
        hideOnClick={'toggle'}
        visible={pickerVisible}
        className="tab-node-tippy"
      >
        <div
          className="input-container"
          onClick={() => {
            setPickerVisible(!pickerVisible);
          }}
        >
          {field.value || placeholder}

          <IconDatepicker
            width="14.7"
            height="15.7"
            fill="var(--color-purple_4)"
            cursor="pointer"
          />
        </div>
      </Tippy>

      {meta.touched && meta.error ? (
        <Typography variant="body3" color="var(--color-red_6)">
          {meta.error}
        </Typography>
      ) : null}
      <style jsx>{`
        .label-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .label {
          font-size: 14px;
          line-height: 24px;
          color: var(--color-purple_6);
          font-weight: 600;
        }
        .input-container {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-top: 5px;
          margin-bottom: 4px;
          padding: 12px;
          font-size: 14px;
          line-height: 24px;
          border-radius: 14px;
          color: ${field.value ? 'var(--color-purple_6)' : 'var(--color-purple_3)'};
          border: 1px solid
            ${meta.touched && meta.error ? 'var(--color-red_6)' : 'var(--color-purple_4)'};
          box-shadow: ${meta.touched && meta.error
            ? '-2px -2px 4px rgba(140, 46, 41, 0.2), 2px 2px 4px rgba(140, 46, 41, 0.2)'
            : 'none'};
          &:hover {
            background: var(--color-gray_3);
          }
        }
      `}</style>
    </div>
  );
});

export default DayPicker;
