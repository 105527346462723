import cn from 'classnames';
import React from 'react';
import Typography from '~components/typography';
import { Tick } from '~svg-components';
import theme from '~theme';

interface Props {
  checked: boolean;
  confirmText: string;
  onClick: () => void;
}

const Confirm: React.FC<Props> = ({ checked, confirmText, onClick }) => {
  return (
    <div className="root">
      <div className={cn('root__checkbox', { active: checked })} onClick={onClick}>
        {checked && <Tick fill={theme.colors.black3B} width={14} />}
      </div>
      <Typography component="div" variant="body3">
        {confirmText}
      </Typography>

      <style jsx>{`
        .root {
          display: flex;
          align-items: flex-start;
          &__checkbox {
            width: 24px;
            height: 24px;
            border-radius: 6px;
            border: solid 1px ${theme.colors.yellow};
            cursor: pointer;
            text-align: center;
            margin-top: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            margin-right: 8px;
            background: ${theme.colors.white};
            &.active {
              background: ${theme.colors.green};
            }
          }
        }
      `}</style>
    </div>
  );
};

export default Confirm;
