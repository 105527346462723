import moment from 'moment';
import {
  TenancyEndDateTypeOption,
  TenancyLengthOptions,
  TenancyLengthTypeOption,
  TenancyOptionRateTypeOption,
  TenancyStartDateTypeOption,
  TenancyTypeOption,
} from '~graphql/__generated__/globalTypes';

export const DATE_FORMAT = 'll';

export const momentAddDays = (date: Date, count: string | number) => {
  return moment(date)
    .add(count, 'days')
    .toDate();
};

export const momentSubtractDays = (date: Date, count: string | number) => {
  return moment(date)
    .subtract(count, 'days')
    .toDate();
};

export const isAfter = (date: Date | number, dateToCompare: Date | number) => {
  return moment(date).diff(moment(dateToCompare), 'days') > 0;
};

export const isSameDay = (date: Date | number, dateToCompare: Date | number) => {
  return moment(date).diff(moment(dateToCompare), 'days') === 0;
};

export interface TenancyOption {
  endDate: string;
  endDateType: TenancyEndDateTypeOption;
  id: string;
  name: string;
  startDate: string;
  startDateType: TenancyStartDateTypeOption;
  tenancyLength: number;
  tenancyLengthType: TenancyLengthTypeOption;
  tenancyType: TenancyTypeOption;
  rateType: TenancyOptionRateTypeOption;
  tenancyLengthOption: TenancyLengthOptions;
}

export const getTenancyOptionRangeDayPickerStartDisabledDays = (tenancyOption: TenancyOption) => {
  const {
    startDate,
    startDateType,
    endDate,
    endDateType,
    tenancyLength,
    tenancyLengthType,
  } = tenancyOption;
  const currentDateIsAfterTenancyStartDate = moment().isSameOrAfter(moment(startDate));
  const parseStartDate = currentDateIsAfterTenancyStartDate
    ? moment().toDate()
    : moment(startDate).toDate();
  const parseEndDate = moment(endDate).toDate();
  const isEQStartDateType = startDateType === TenancyStartDateTypeOption.EQ;
  const isEQEndDateType = endDateType === TenancyEndDateTypeOption.EQ;
  const isEQTenancyLengthType = tenancyLengthType === TenancyLengthTypeOption.EQ;
  const isLETenancyLengthType = tenancyLengthType === TenancyLengthTypeOption.LE;
  let disabledDaysRules: any[] = [{ before: parseStartDate }, { after: parseEndDate }];

  if (isEQStartDateType) {
    disabledDaysRules.push({
      from: momentAddDays(parseStartDate, isLETenancyLengthType ? tenancyLength + 1 : 1),
      to: isLETenancyLengthType ? parseEndDate : momentAddDays(parseStartDate, tenancyLength - 1),
    });
    return disabledDaysRules;
  }

  if (isEQEndDateType) {
    const disabledBefore = isLETenancyLengthType
      ? momentSubtractDays(parseEndDate, tenancyLength)
      : parseStartDate;
    const disabledAfter = momentSubtractDays(
      parseEndDate,
      isLETenancyLengthType ? 1 : tenancyLength
    );
    disabledDaysRules = [{ before: disabledBefore }, { after: disabledAfter }];
    return disabledDaysRules;
  }

  if (isEQTenancyLengthType) {
    disabledDaysRules = [
      { before: parseStartDate },
      { after: momentSubtractDays(parseEndDate, tenancyLength) },
    ];
    return disabledDaysRules;
  }
  return disabledDaysRules;
};

export const getTenancyOptionRangeDayPickerEndDisabledDays = (
  tenancyOption: TenancyOption,
  rangeDateFrom: Date | undefined
) => {
  const {
    startDate,
    startDateType,
    endDate,
    endDateType,
    tenancyLength,
    tenancyLengthType,
  } = tenancyOption;
  const parseStartDate = moment(startDate).toDate();
  const parseEndDate = moment(endDate).toDate();
  const isEQStartDateType = startDateType === TenancyStartDateTypeOption.EQ;
  const isEQEndDateType = endDateType === TenancyEndDateTypeOption.EQ;
  const isLETenancyLengthType = tenancyLengthType === TenancyLengthTypeOption.LE;
  const isGETenancyLengthType = tenancyLengthType === TenancyLengthTypeOption.GE;
  let disabledDaysRules: any[] = [{ before: parseStartDate }, { after: parseEndDate }];

  if (isEQStartDateType && rangeDateFrom) {
    const disabledBefore = momentAddDays(parseStartDate, isLETenancyLengthType ? 1 : tenancyLength);
    const disabledAfter = isLETenancyLengthType
      ? momentAddDays(parseStartDate, tenancyLength)
      : parseEndDate;
    disabledDaysRules = [{ before: disabledBefore }, { after: disabledAfter }];
    return disabledDaysRules;
  }

  if (isEQEndDateType) {
    const disabledBefore = parseEndDate;
    const disabledAfter = parseEndDate;
    return [{ before: disabledBefore }, { after: disabledAfter }];
  }

  if (isLETenancyLengthType && rangeDateFrom) {
    const parseRangeDateFrom = moment(rangeDateFrom).toDate();

    const tenancyLengthDisabledDaysBeforeSeletedDate = {
      from: momentSubtractDays(parseStartDate, 1),
      to: momentSubtractDays(parseRangeDateFrom, tenancyLength + 1),
    };
    const tenancyLengthDisabledDaysAfterSeletedDate = {
      from: momentAddDays(parseRangeDateFrom, tenancyLength + 1),
      to: parseEndDate,
    };

    const tenancyLengthDisabledRangeDateFrom = parseRangeDateFrom;

    disabledDaysRules.push(
      tenancyLengthDisabledDaysBeforeSeletedDate,
      tenancyLengthDisabledDaysAfterSeletedDate,
      tenancyLengthDisabledRangeDateFrom
    );

    return disabledDaysRules;
  }

  if (isGETenancyLengthType && rangeDateFrom) {
    const parseRangeDateFrom = moment(rangeDateFrom).toDate();

    const tenancyLengthDisabledDaysBeforeSeletedDate = {
      from: momentSubtractDays(parseRangeDateFrom, tenancyLength - 1),
      to: momentAddDays(parseRangeDateFrom, tenancyLength - 1),
    };

    disabledDaysRules.push(tenancyLengthDisabledDaysBeforeSeletedDate);
    return disabledDaysRules;
  }
  return disabledDaysRules;
};

type TenancyOptionInfosTenancyOption = {
  endDate: any | null;
  endDateType: TenancyEndDateTypeOption | null;
  id: string | null;
  name: string;
  originId: number | null;
  startDate: any | null;
  startDateType: TenancyStartDateTypeOption | null;
  tenancyLength: any | null;
  tenancyLengthType: TenancyLengthTypeOption | null;
  tenancyType: TenancyTypeOption;
  rateType: TenancyOptionRateTypeOption;
  tenancyLengthOption: TenancyLengthOptions;
};

interface TenancyOptionInfos {
  tenancyOption: TenancyOptionInfosTenancyOption | null;
  numberOfAvailableBeds: number | null;
}

function tenancyOptionInfosToTenancyOptions(
  tenancyOptionInfos: (TenancyOptionInfos | null)[]
): TenancyOption[] {
  const availableTenancyOptions =
    tenancyOptionInfos
      ?.filter(
        (
          tenancyOptionInfo
        ): tenancyOptionInfo is {
          tenancyOption: NonNullable<TenancyOptionInfos['tenancyOption']>;
          numberOfAvailableBeds: TenancyOptionInfos['numberOfAvailableBeds'];
        } => !!tenancyOptionInfo && tenancyOptionInfo.numberOfAvailableBeds !== 0
      )
      .map(({ tenancyOption }) => tenancyOption) ?? [];

  const tenancyOptions: TenancyOption[] = [];

  availableTenancyOptions.forEach(item => {
    if (item.id && item.tenancyLengthType && item.startDateType && item.endDateType) {
      tenancyOptions.push({
        id: item.id,
        name: item.name,
        tenancyLengthType: item.tenancyLengthType,
        tenancyType: item.tenancyType,
        startDate: item.startDate,
        endDate: item.endDate,
        startDateType: item.startDateType,
        endDateType: item.endDateType,
        tenancyLength: item.tenancyLength,
        rateType: item.rateType,
        tenancyLengthOption: item.tenancyLengthOption,
      });
    }
  });

  tenancyOptions.sort((current, next) => {
    return current.name.toLowerCase() > next.name.toLowerCase() ? 1 : -1;
  });

  return tenancyOptions;
}

interface AcademicYearInfos {
  academicYear: { id?: string | null; name: string } | null;
  tenancyOptionInfos: (TenancyOptionInfos | null)[] | null;
}

export function academicYearInfosToTenancyOptionsList(
  academicYearInfos?: (AcademicYearInfos | null)[] | null
) {
  if (!academicYearInfos) return [];

  return academicYearInfos
    .filter(
      (
        item
      ): item is {
        academicYear: { id: string; name: string };
        tenancyOptionInfos: NonNullable<AcademicYearInfos['tenancyOptionInfos']>;
      } => !!(item?.academicYear?.id && item?.tenancyOptionInfos)
    )
    .map(item => {
      return {
        academicYear: item.academicYear,
        tenancyOptions: tenancyOptionInfosToTenancyOptions(item.tenancyOptionInfos),
      };
    });
}

export default getTenancyOptionRangeDayPickerStartDisabledDays;
