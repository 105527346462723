import classnames from 'classnames';
import { useField } from 'formik';
import React from 'react';
import styles from './radio.module.scss';

interface RadioProps {
  name: string;
  disabled?: boolean;
  label?: string | React.ReactNode;
  value?: string | boolean | number;
  className?: string;
  error?: string;
  labelClassName?: string;
  radioClassName?: string;
}

const Radio = React.forwardRef<any, RadioProps & React.InputHTMLAttributes<HTMLInputElement>>(
  (props, ref) => {
    const { label, className, radioClassName, labelClassName, value, ...others } = props;
    const [field] = useField(props);
    return (
      <div className={classnames('root', className)}>
        <div
          className={classnames(styles.container, {
            [styles.containerDisabled]: others.disabled,
          })}
        >
          <input
            checked={field.value === value}
            className={styles.input}
            type="radio"
            ref={ref}
            {...field}
            {...others}
            value={value}
          />
          <span
            className={classnames(
              styles.custom,
              {
                [styles.customDisabled]: others.disabled,
              },
              radioClassName
            )}
          ></span>
        </div>
        <span
          className={classnames(
            styles.label,
            {
              [styles.labelDisabled]: others.disabled,
            },
            labelClassName
          )}
        >
          {label}
        </span>
      </div>
    );
  }
);

export default Radio;
