import classnames from 'classnames';
import { useField } from 'formik';
import React from 'react';
import Typography from '~components/typography/typography';
import { More } from '~svg-components';

interface InputProps {
  name: string;
  placeholder?: string;
  label?: string;
  className?: string;
  error?: string;
  inputClassName?: string;
  labelClassName?: string;
  errorComponent?: React.ReactNode;
  min?: number;
  max?: number;
  onValueChange?: (value: number) => void;
}

const Input = React.forwardRef<any, InputProps & React.InputHTMLAttributes<HTMLInputElement>>(
  (props, ref) => {
    const {
      label,
      name,
      className,
      inputClassName,
      labelClassName,
      errorComponent,
      min,
      max,
      onValueChange,
      ...others
    } = props;
    const [field, meta, helpers] = useField(name);

    return (
      <div className={classnames('root', className)}>
        <div className="label-container">
          {label && (
            <label className={classnames('label', labelClassName)} htmlFor={name}>
              {label}
            </label>
          )}
        </div>
        <div className="input-number-container">
          <input
            type="text"
            className={classnames(
              'input-number',
              { 'input-error': meta.touched && meta.error },
              inputClassName
            )}
            ref={ref}
            {...others}
            {...field}
            onChange={e => {
              const inputValue = e.target.value;
              if (inputValue === '-') {
                helpers.setValue(0);
              }
              if (!isNaN(Number(inputValue))) {
                const fieldValue = Number(inputValue);
                if ((max || max === 0) && (min || min === 0)) {
                  if (fieldValue <= max && fieldValue >= min) {
                    helpers.setValue(fieldValue);
                    if (onValueChange) onValueChange(fieldValue);
                  }
                } else if (max || max === 0) {
                  if (fieldValue <= max) {
                    helpers.setValue(fieldValue);
                    if (onValueChange) onValueChange(fieldValue);
                  }
                } else if (min || min === 0) {
                  if (fieldValue >= min) {
                    helpers.setValue(fieldValue);
                    if (onValueChange) onValueChange(fieldValue);
                  }
                } else {
                  helpers.setValue(fieldValue);
                  if (onValueChange) onValueChange(fieldValue);
                }
              }
            }}
          />
          <div className="input-actions">
            <div
              className="input-actions-item"
              onClick={() => {
                const fieldValue = Number(field.value);
                if (max || max === 0) {
                  if (fieldValue < max) {
                    helpers.setValue(fieldValue + 1);
                    if (onValueChange) onValueChange(fieldValue + 1);
                  }
                } else {
                  helpers.setValue(fieldValue + 1);
                  if (onValueChange) onValueChange(fieldValue + 1);
                }
                setTimeout(() => {
                  helpers.setTouched(true);
                }, 100);
              }}
            >
              <More
                width="8"
                height="5"
                fill="var(--color-greyText)"
                className="input-actions-item-icon"
              />
            </div>
            <div
              className="input-actions-item"
              onClick={() => {
                const fieldValue = Number(field.value);
                if (min || min === 0) {
                  if (fieldValue > min) {
                    helpers.setValue(fieldValue - 1);
                    if (onValueChange) onValueChange(fieldValue - 1);
                  }
                } else {
                  helpers.setValue(fieldValue - 1);
                  if (onValueChange) onValueChange(fieldValue - 1);
                }
                setTimeout(() => {
                  helpers.setTouched(true);
                }, 100);
              }}
            >
              <More width="8" height="5" fill="var(--color-greyText)" />
            </div>
          </div>
        </div>
        {meta.touched && meta.error ? (
          <Typography variant="body3" color="var(--color-red_6)">
            {errorComponent || meta.error}
          </Typography>
        ) : null}
        <style jsx>{`
          .label-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .label {
            font-size: 14px;
            line-height: 24px;
            color: var(--color-purple_6);
            font-weight: 600;
          }
          .input-number-container {
            position: relative;
          }
          :global(.input-actions-item-icon) {
            transform: rotate(180deg);
          }
          .input-actions {
            position: absolute;
            width: 32px;
            top: 6px;
            right: 1px;
            height: 48px;
            border-left: 1px solid var(--color-outline);
            display: flex;
            flex-direction: column;
          }
          .input-actions-item {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:nth-child(1) {
              border-bottom: 1px solid var(--color-outline);
            }
          }
          .input-number {
            margin-top: 5px;
            margin-bottom: 4px;
            width: 100%;
            padding: 12px;
            padding-left: 12px;
            padding-right: 32px;
            font-size: 14px;
            line-height: 24px;
            border-radius: 14px;
            color: var(--color-purple_6);
            outline: none;
            border: 1px solid var(--color-purple_4);
            background: none !important;

            &:hover {
              background: var(--color-gray_3) !important;
            }

            &:focus {
              border-color: var(--color-purple_6);
            }

            &::placeholder {
              color: var(--color-greyText);
              font-size: 14px;
            }

            &.input-error {
              border-color: var(--color-red_6);
            }
          }
        `}</style>
      </div>
    );
  }
);

export default Input;
