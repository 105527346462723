import React, { useState } from 'react';
import DayPicker, { DayPickerProps, LocaleUtils } from 'react-day-picker';

interface Props {
  date?: Date;
  localeUtils: LocaleUtils;
  fromMonthProps: Date;
  toMonthProps: Date;
  onChange: (date: Date) => void;
}
const YearMonthForm: React.FC<Props> = function YearMonthForm({
  date,
  localeUtils,
  onChange,
  fromMonthProps,
  toMonthProps,
}) {
  const months = localeUtils.getMonths();
  const years = [];
  for (let i = toMonthProps.getFullYear(); i >= fromMonthProps.getFullYear(); i -= 1) {
    years.push(i);
  }
  const handleChange = function handleChange(e: any) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className="DayPicker-Caption">
      <div className="select-wrapper">
        <select
          className="year-month-form-select"
          name="month"
          onChange={handleChange}
          value={date?.getMonth()}
        >
          {months.map((month: any, i: any) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
        </select>
      </div>
      <div className="select-wrapper">
        <select
          className="year-month-form-select"
          name="year"
          onChange={handleChange}
          value={date?.getFullYear()}
        >
          {years.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <style jsx>
        {`
          .select-wrapper {
            position: relative;
            margin-right: 10px;
            display: inline-block;
            &:after {
              position: absolute;
              top: 18px;
              right: 12px;
              border-top: 6px solid var(--color-purple_4);
              border-right: 4px solid var(--color-transparent);
              border-left: 4px solid var(--color-transparent);
              content: '';
              pointer-events: none;
            }
          }
          .year-month-form-select {
            font-size: 14px;
            color: var(--color-purple_6);
            height: 40px;
            padding: 0 22px 0 12px;
            border: 1px solid var(--color-purple_4);
            border-radius: 14px;
            outline: none;
            background-color: transparent;
            appearance: none;
            cursor: pointer;
            &::-ms-expand {
              display: none;
            }
          }
        `}
      </style>
    </form>
  );
};

interface PickerProps {
  fromMonthProps: Date;
  toMonthProps: Date;
  disabledDays?: DayPickerProps['disabledDays'];
  onSelectDate: (date: Date) => void;
}
const FixedDayPicker = React.forwardRef<any, PickerProps & React.HTMLAttributes<HTMLDivElement>>(
  ({ onSelectDate, fromMonthProps, toMonthProps, disabledDays }, ref) => {
    const [month, setMonth] = useState(fromMonthProps);
    const [date, setDate] = useState<Date | undefined>();
    const handleYearMonthChange = (month: Date) => {
      setMonth(month);
    };
    return (
      <>
        <DayPicker
          ref={ref}
          month={month}
          disabledDays={disabledDays}
          fromMonth={fromMonthProps}
          toMonth={new Date(toMonthProps.getFullYear())}
          onDayClick={(date, modifiers) => {
            if (modifiers?.disabled) {
              return;
            }
            setDate(date);
            onSelectDate(date);
          }}
          selectedDays={[date]}
          captionElement={({ date, localeUtils }) => (
            <YearMonthForm
              toMonthProps={toMonthProps}
              fromMonthProps={fromMonthProps}
              date={date}
              localeUtils={localeUtils}
              onChange={handleYearMonthChange}
            />
          )}
        />
        <style jsx global>
          {`
            .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
              background: var(--color-purple_6) !important;
              color: var(--color-white);
            }
            .DayPicker-Months {
              width: 264px;
              background: var(--color-white);
            }
            .DayPicker:not(.DayPicker--interactionDisabled)
              .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
              background: var(--color-purple_1);
            }
            .DayPicker-wrapper {
              box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
            }
            .DayPicker-Weekday {
              color: var(--color-purple_4);
            }
            .DayPicker-Day--disabled {
              color: var(--color-gray_6);
            }
          `}
        </style>
      </>
    );
  }
);

export default FixedDayPicker;
