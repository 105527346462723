import classnames from 'classnames';
import React, { useImperativeHandle, useRef } from 'react';
import Typography from '~components/typography/typography';
import styles from './adder.module.scss';

interface AddProps {
  onValueChange: (number: number) => void;
  min?: number;
  max?: number;
  value: number;
  className?: string;
  name?: string;
}

const Adder = React.forwardRef<any, AddProps & React.InputHTMLAttributes<HTMLInputElement>>(
  (props, ref) => {
    const { min = 0, max = 999, onValueChange, className, name, value, ...others } = props;
    const inputRef = useRef<any>();
    useImperativeHandle(ref, () => ({
      getValue: () => {
        return inputRef?.current?.value;
      },
    }));
    return (
      <div className={styles.root}>
        <Typography
          className={classnames(styles.symbol, { [styles.symbolDisabled]: value === 0 }, className)}
          color="var(--color-white)"
          component="div"
          onClick={() => {
            if (value > min) {
              onValueChange(value - 1);
            }
          }}
        >
          —
        </Typography>
        <input
          className={styles.input}
          min={min}
          type="number"
          ref={inputRef}
          value={value}
          onChange={e => {
            onValueChange(Number(e.target.value));
          }}
          {...others}
        />
        <Typography
          className={classnames(
            styles.symbol,
            { [styles.symbolDisabled]: value === max },
            className
          )}
          color="var(--color-white)"
          component="div"
          onClick={() => {
            if (value < max) {
              onValueChange(value + 1);
            }
          }}
        >
          +
        </Typography>
      </div>
    );
  }
);

export default Adder;
