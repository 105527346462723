import classNames from 'classnames';
import React from 'react';
const Divider: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <>
    <hr className={classNames('divider', props.className)} style={{ ...props.style }} />
    <style jsx>{`
      .divider {
        border: none;
        height: 1px;
        width: 100%;
        margin: 0;
        flex-shrink: 0;
        background-color: var(--color-purple_2);
      }
    `}</style>
  </>
);

export default Divider;
