import { useFormikContext } from 'formik';
import { useEffect } from 'react';

const FocusError = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      let keys = Object.keys(errors);
      let errorElement: any;
      if (keys.length > 0) {
        try {
          errorElement = document.querySelector(`[name=${keys[0]}]`) as HTMLElement;
        } catch (e) {
          errorElement = document.getElementById(`${keys[0]}`) as HTMLElement;
        }
      }
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
          errorElement.focus();
        }, 300);
      }
    }
  }, [errors, isSubmitting, isValidating]);

  return null;
};

export default FocusError;
