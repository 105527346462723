import classnames from 'classnames';
import { useField } from 'formik';
import React from 'react';
import Typography from '~components/typography/typography';
import styles from './name-input.module.scss';

interface InputProps {
  firstName: string;
  lastName: string;
  type?: string;
  firstPlaceholder?: string;
  lastPlaceholder?: string;
  label?: string;
  labelRight?: string | React.ReactNode;
  className?: string;
  error?: string;
  inputClassName?: string;
  labelClassName?: string;
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  errorComponent?: React.ReactNode;
  divideComponent?: React.ReactNode;
}

const NameInput = React.forwardRef<any, InputProps & React.InputHTMLAttributes<HTMLInputElement>>(
  (props, ref) => {
    const {
      label,
      labelRight,
      firstName,
      lastName,
      prefix,
      suffix,
      className,
      inputClassName,
      labelClassName,
      errorComponent,
      firstPlaceholder,
      lastPlaceholder,
      divideComponent,
      ...others
    } = props;
    const [firstField, firstMeta] = useField(firstName);
    const [lastField, lastMeta] = useField(lastName);
    return (
      <div className={className}>
        <div className={styles.labelContainer}>
          {label && <label className={classnames(styles.label, labelClassName)}>{label}</label>}
          <span className={styles.labelRight}>{labelRight}</span>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputPrefix}>{prefix}</div>
          <div
            className={classnames(styles.inputGroup, {
              [styles.inputGroupError]:
                (firstMeta.touched && firstMeta.error) || (lastMeta.touched && lastMeta.error),
              inputClassName,
            })}
          >
            <input
              className={classnames(
                styles.input,
                {
                  [styles.inputHasPrefix]: !!prefix,
                },
                {
                  [styles.inputHasSuffix]: !!suffix,
                },
                {
                  [styles.inputError]: firstMeta.touched && firstMeta.error,
                }
              )}
              ref={ref}
              placeholder={firstPlaceholder}
              {...others}
              {...firstField}
            />
            {divideComponent || <div className={styles.divide} />}
            <input
              className={classnames(
                styles.input,
                {
                  [styles.inputHasPrefix]: !!prefix,
                },
                {
                  [styles.inputHasSuffix]: !!suffix,
                },
                {
                  [styles.inputError]: lastMeta.touched && lastMeta.error,
                }
              )}
              ref={ref}
              placeholder={lastPlaceholder}
              {...others}
              {...lastField}
            />
          </div>
          <div className={styles.inputSuffix}>{suffix}</div>
        </div>
        {(firstMeta.touched && firstMeta.error) || (lastMeta.touched && lastMeta.error) ? (
          <Typography variant="body3" color="var(--color-red_6)">
            {errorComponent || firstMeta.error || lastMeta.error}
          </Typography>
        ) : null}
      </div>
    );
  }
);

export default NameInput;
