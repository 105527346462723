import classnames from 'classnames';
import { useField, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Select, {
  components,
  IndicatorProps,
  MenuProps,
  Props,
  ValueContainerProps,
} from 'react-select';
import Typography from '~components/typography/typography';
import { useTranslation } from '~i18n';
import { Check, IconJumpDown } from '~svg-components';
import stylesModules from './select-multi.module.scss';

interface ISelectProps extends Props {
  creatable?: boolean;
  name: string;
  cacheOptions?: boolean;
  loadOptions?: (inputValue: string) => void;
  placeholder?: string;
  label?: string;
  labelRight?: string | React.ReactNode;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  isMobile?: boolean;
  onSelect?: (option: any) => void;
}

const Menu = (props: MenuProps<any>) => {
  const { t } = useTranslation();

  return (
    <>
      <components.Menu {...props}>
        <>
          {props.children}
          {props.options?.length > 0 && (
            <div className="select-multi-menu-select-all">
              <Typography
                variant="body3"
                cursor="pointer"
                textDecoration="underline"
                onClick={() => {
                  props.selectProps.selectAll?.();
                }}
              >
                {t('select_all')}
              </Typography>
              {props.selectProps.isMobile && (
                <Typography
                  variant="body3"
                  cursor="pointer"
                  textDecoration="underline"
                  onClick={() => {
                    props.selectProps.setMenuOpen(false);
                  }}
                >
                  {t('confirm')}
                </Typography>
              )}
            </div>
          )}
        </>
      </components.Menu>
      <style jsx>{`
        .select-multi-menu-select-all {
          width: 100%;
          height: 48px;
          padding: 0 12px;
          display: flex;
          align-items: center;
          justify-content: ${props.selectProps.isMobile ? 'space-between' : 'flex-end'};
        }
      `}</style>
    </>
  );
};

const ValueContainer = (props: ValueContainerProps<any>) => {
  const values = props.getValue();
  const options = props.options || [];
  const children = props.children as any;
  const lastChild = children[children.length - 1];
  const labels = values?.map((item: any) => item?.label)?.join(', ');
  const { t } = useTranslation();

  return (
    <components.ValueContainer {...props}>
      {options?.length > 0 && values?.length === options?.length && (
        <>
          <Typography variant="body3">{`${t('all')} ${props.selectProps.label}`}</Typography>
          {lastChild}
        </>
      )}
      {options?.length > 0 && values?.length > 0 && values?.length !== options?.length && (
        <>
          <Typography
            variant="body3"
            component="div"
            className="custom-select-multi-value-container"
          >
            {labels}
          </Typography>
          {lastChild}
        </>
      )}
      {values?.length === 0 && children}
      <style jsx>{`
        :global(.custom-select-multi-value-container) {
          width: 90%;
          height: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      `}</style>
    </components.ValueContainer>
  );
};

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <div className="select-multi-option">
        <Typography variant="body3">{props.label}</Typography>
        {props.isSelected && <Check width="16" height="16" />}
        <style jsx>{`
          .select-multi-option {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        `}</style>
      </div>
    </components.Option>
  );
};

const DropdownIndicator = (props: IndicatorProps<any>) => {
  return (
    <components.DropdownIndicator {...props}>
      <>
        <div
          className={classnames('dropdown-icon', { 'input-focused': props.selectProps.menuIsOpen })}
        >
          <IconJumpDown width="8" height="6" fill="var(--color-grey_99)" />
        </div>
        <style jsx>
          {`
            .dropdown-icon {
              margin-top: 2px;
              width: 8px;
              height: 24px;
              transform: rotate(180deg);
            }
            .input-focused {
              transform: rotate(0);
            }
          `}
        </style>
      </>
    </components.DropdownIndicator>
  );
};

const SelectComponent = React.forwardRef<
  any,
  ISelectProps & React.SelectHTMLAttributes<HTMLSelectElement>
>((props, ref) => {
  const {
    label,
    labelRight,
    name,
    loadOptions,
    className,
    inputClassName,
    labelClassName,
    options,
    cacheOptions,
    isDisabled,
    isMobile,
    styles,
    onSelect,
    ...others
  } = props;
  const { t } = useTranslation();
  const [field, meta, { setValue, setTouched }] = useField(name);
  const { setFieldValue } = useFormikContext();

  const [menuOpen, setMenuOpen] = useState(false);
  let selectRef = useRef<any>(null);
  const customStyles = {
    singleValue: (provided: any, state: any) => {
      return { ...provided, fontSize: 14, color: 'var(--color-purple_6)' };
    },
    container: (provided: any, state: { isFocused: any }) => {
      return { ...provided, marginTop: '5px', width: '100%', marginBottom: '4px' };
    },
    control: (provided: any, state: { isFocused: any }) => {
      let borderColor = state.isFocused ? 'var(--color-purple_6)' : 'var(--color-purple_4)';
      let boxShadow = state.isFocused
        ? '-2px -2px 4px rgba(64, 46, 82, 0.2), 2px 2px 4px rgba(64, 46, 82, 0.2)'
        : 'none';
      let hover = {
        ':hover': {
          borderColor: 'var(--color-purple_4)',
          backgroundColor: 'var(--color-gray_3)',
        },
      };
      if (meta.error && meta.touched) {
        borderColor = 'var(--color-red_6)';
      }
      return { ...provided, boxShadow, borderColor, ...hover };
    },
    input: (provided: any, state: { isFocused: any }) => {
      return {
        ...provided,
        fontSize: 14,
        lineHeight: '24px',
        color: 'var(--color-purple_6)',
        margin: 0,
        padding: '0',
        display: 'inline',
      };
    },
    indicatorSeparator: () => {
      return {};
    },
    valueContainer: (provided: any) => {
      return {
        ...provided,
        padding: '12px 10px',
        display: 'flex',
      };
    },
    menu: (provided: any) => {
      return {
        ...provided,
        overflow: 'hidden',
      };
    },
    placeholder: (provided: any) => {
      return {
        ...provided,
        color: 'var(--color-purple_4)',
        fontSize: 14,
      };
    },
    option: (provided: any, state: any) => ({
      ...provided,
      background: state.isFocused ? 'var(--color-purple_1)' : 'var(--color-white)',
      padding: 12,
      ':active': {
        backgroundColor: 'var(--color-purple_1)',
      },
    }),
    multiValueRemove: () => ({
      display: 'none',
    }),
    multiValue: () => ({
      background: 'none',
      display: 'inline',
    }),
    ...styles,
  };

  const handleSelectAll = useCallback(() => {
    setFieldValue(name, options);
    if (onSelect) onSelect(options);
  }, [name, onSelect, options, setFieldValue]);

  useEffect(() => {
    if (process.browser && !isDisabled) {
      if (selectRef?.current.select.inputRef) {
        selectRef?.current?.select?.inputRef?.setAttribute('name', name);
      } else if (selectRef?.current?.select?.select?.select?.inputRef) {
        selectRef?.current.select.select.select.inputRef?.setAttribute('name', name);
      }
    }
  }, [name, isDisabled]);

  const selectCustomProps = {
    inputRef: ref,
    styles: customStyles,
    components: {
      DropdownIndicator,
      Option,
      Menu,
      ValueContainer,
    },
    onMenuOpen: () => {
      setMenuOpen(true);
    },
    onMenuClose: () => {
      if (!isMobile) {
        setMenuOpen(false);
      }
    },
    isClearable: false,
    isDisabled,
    theme: (theme: any) => ({
      ...theme,
      borderRadius: 14,
      colors: {
        ...theme.colors,
        primary25: 'var(--color-purple_1)',
        primary: 'var(--color-purple_6)',
        neutral90: 'var(--color-outline)',
      },
    }),
    ...others,
    name: field.name,
    hideSelectedOptions: false,
    backspaceRemovesValue: false,
    menuIsOpen: menuOpen,
  };

  return (
    <div className={className}>
      <div className={stylesModules.labelContainer}>
        {label && (
          <label className={classnames(stylesModules.label, labelClassName)} htmlFor={name}>
            {label}
          </label>
        )}
        <span className={stylesModules.labelRight}>{labelRight}</span>
      </div>
      <div className={stylesModules.inputContainer}>
        <Select
          className="select-multi-component-container"
          closeMenuOnSelect={false}
          isMulti
          options={options}
          selectAll={handleSelectAll}
          isMobile={isMobile}
          setMenuOpen={setMenuOpen}
          label={label}
          {...selectCustomProps}
          onChange={(option): void => {
            setValue(option);
            if (onSelect) onSelect(option);
          }}
          ref={selectRef}
          noOptionsMessage={() => t('form.select.no_result')}
          onBlur={() => {
            setTimeout(() => {
              setTouched(true);
            }, 100);
          }}
          value={field.value}
        />
      </div>
      {meta.touched && meta.error ? (
        <Typography variant="body3" color="var(--color-red_6)">
          {meta.error}
        </Typography>
      ) : null}
    </div>
  );
});
export default SelectComponent;
