import { UAContext } from '@quentin-sommer/react-useragent';
import React, { useContext, useRef, useState } from 'react';
import { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import Typography from '~components/typography/typography';
import { useTranslation } from '~i18n';
import { IconDatepicker, IconRight } from '~svg-components';

type dateType = 'startDate' | 'endDate';
interface IDateRangePickerProps {
  initialStartDate?: Date;
  initialEndDate?: Date;
  fixedNights?: number;
  afterStartDate: Date;
  beforeEndDate: Date;
  minimumNights?: number;
  onFinishSelect: (arg: { startDate: any; endDate: any }) => void;
}

const DateRangePickerWrapper: React.FC<IDateRangePickerProps> = props => {
  const {
    initialStartDate,
    fixedNights,
    initialEndDate,
    minimumNights,
    onFinishSelect,
    afterStartDate,
    beforeEndDate,
  } = props;
  const uaResults = useContext(UAContext).uaResults as UAResults;
  const isMobile = uaResults.mobile;
  const { t } = useTranslation('student_portal');
  const endDateRef = useRef<any>(null);
  const startDateRef = useRef<any>(null);
  const [date, setDate] = useState({
    startDate: initialStartDate,
    endDate: initialEndDate,
  });
  const [key, setKey] = useState(+new Date() + Math.random());
  const modifiers = { start: date.startDate, end: date.endDate };
  const differNights = minimumNights || fixedNights;
  const lastEndDate = date.endDate || beforeEndDate;
  const earliestStartDate = date.startDate || afterStartDate;

  const getSelectedDays = () => {
    if (date.endDate && date.startDate) {
      return [{ from: date.startDate, to: date.endDate }];
    } else return [];
  };

  const getEndAvailableDate = () => {
    if (differNights && earliestStartDate) {
      const endAvailableDate = DateUtils.clone(earliestStartDate);
      endAvailableDate.setDate(endAvailableDate.getDate() + differNights);
      return endAvailableDate;
    }
    if (date.startDate) {
      return date.startDate;
    }
    return afterStartDate;
  };

  const handleDayMouseEnter = (day: any) => {
    setDate({
      ...date,
      endDate: day,
    });
  };

  const getStartAvailableDate = () => {
    if (differNights && lastEndDate) {
      const startAvailableDate = DateUtils.clone(lastEndDate);
      startAvailableDate.setDate(startAvailableDate.getDate() - differNights);
      return startAvailableDate;
    }
    if (date.endDate) {
      return date.endDate;
    }
    return beforeEndDate;
  };

  const getDisableDays = (type: dateType) => {
    if (type === 'startDate') {
      return { after: getStartAvailableDate(), before: afterStartDate };
    }
    if (type === 'endDate') {
      return { after: beforeEndDate, before: getEndAvailableDate() };
    }
  };

  return (
    <div className="input-from-to" key={key}>
      <IconDatepicker width="14.7" height="15.7" fill="var(--color-greyText)" />
      <span className="input-from-to__from">
        <DayPickerInput
          value={date.startDate}
          placeholder={t('select_bed.tenancy_option.move_in_time')}
          ref={startDateRef}
          overlayComponent={(props: any) => (
            <CustomOverlay
              onClearDate={() => {
                setDate({
                  startDate: undefined,
                  endDate: undefined,
                });
                setKey(+new Date() + Math.random());
              }}
              t={t}
              isMobile={isMobile}
              {...props}
            />
          )}
          showOverlay
          format="ll"
          inputProps={{
            readOnly: 'readonly',
          }}
          formatDate={MomentLocaleUtils.formatDate}
          parseDate={MomentLocaleUtils.parseDate}
          dayPickerProps={{
            selectedDays: getSelectedDays(),
            disabledDays: getDisableDays('startDate'),
            toMonth: beforeEndDate,
            month: earliestStartDate,
            fromMonth: afterStartDate,
            modifiers,
            numberOfMonths: 1,

            onDayClick: () => {
              endDateRef.current.getInput().focus();
              endDateRef.current.showDayPicker();
            },
          }}
          onDayPickerHide={() => {
            if (document.activeElement === startDateRef.current.getInput()) {
              startDateRef.current.getInput().focus();
              startDateRef.current.showDayPicker();
            } else {
              endDateRef.current.getInput().focus();
              endDateRef.current.showDayPicker();
            }
          }}
          onDayChange={start => {
            if (fixedNights && start) {
              const endDate = DateUtils.clone(start);
              endDate.setDate(endDate.getDate() + fixedNights);

              setDate({
                ...date,
                startDate: start,
                endDate,
              });
              onFinishSelect({
                startDate: start,
                endDate,
              });
              return;
            }
            if (start) {
              setDate({
                ...date,
                startDate: start,
              });
            }
          }}
        />
      </span>
      <IconRight
        width="11.9"
        height="11.9"
        fill="var(--color-grey4c)"
        className="date-range-picker__icon-right"
      />
      <span className="input-from-to__to">
        <DayPickerInput
          ref={endDateRef}
          overlayComponent={(props: any) => (
            <CustomOverlay
              t={t}
              isMobile={isMobile}
              onClearDate={() => {
                setDate({
                  startDate: undefined,
                  endDate: undefined,
                });
                setKey(+new Date() + Math.random());
                startDateRef.current.getInput().focus();
              }}
              {...props}
            />
          )}
          inputProps={{
            readOnly: 'readonly',
          }}
          value={date.endDate}
          placeholder={t('select_bed.tenancy_option.move_out_time')}
          format="ll"
          onDayPickerHide={() => {
            startDateRef.current.showDayPicker();
            startDateRef.current.getInput().focus();
          }}
          formatDate={MomentLocaleUtils.formatDate}
          parseDate={MomentLocaleUtils.parseDate}
          hideOnDayClick={false}
          dayPickerProps={{
            selectedDays: getSelectedDays(),
            disabledDays: getDisableDays('endDate'),
            modifiers,
            month: getEndAvailableDate(),
            toMonth: beforeEndDate,
            fromMonth: afterStartDate,
            numberOfMonths: 1,
            onDayMouseEnter: handleDayMouseEnter,
          }}
          onDayChange={end => {
            if (fixedNights && end) {
              const startDate = DateUtils.clone(end);
              startDate.setDate(startDate.getDate() - fixedNights);

              setDate({
                ...date,
                startDate: startDate,
                endDate: end,
              });
              onFinishSelect({
                startDate: startDate,
                endDate: end,
              });
              return;
            }
            if (end) {
              setDate({
                ...date,
                endDate: end,
              });
              endDateRef.current.showDayPicker();
              onFinishSelect({ startDate: date.startDate, endDate: end });
            }
          }}
        />
      </span>
      <style jsx global>
        {`
          .DayPickerInput {
            input {
              border: 0;
              font-size: 14px;
              line-height: 40px;
              width: ${isMobile ? '105px' : '100px'};
              color: var(--color-purple_6);
            }
          }
          .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
            background: var(--color-purple_1) !important;
            color: var(--color-purple_6);
          }
          .DayPicker-Day {
            font-size: ${isMobile ? '18px' : '14px'};
            border-radius: 0 !important;
          }
          .DayPicker-Month {
            margin: 0 !important;
          }
          .DayPicker {
            margin: 0 auto;
          }
          .DayPicker-NavButton {
            top: 0;
            right: 0.5em;
          }
          .DayPicker-Day--start[aria-selected='true'] {
            background: var(--color-purple_6) !important;
            border-top-left-radius: 50% !important;
            color: var(--color-purple_6) !important;
            border-bottom-left-radius: 50% !important;
          }
          .DayPicker-Day--end[aria-selected='true'] {
            background: var(--color-purple_6) !important;
            border-top-right-radius: 50% !important;
            color: var(--color-purple_6) !important;
            border-bottom-right-radius: 50% !important;
          }
          .DayPicker-Caption > div {
            font-size: 14px;
          }
          .DayPickerInput-Overlay {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: ${isMobile ? '0' : '42px'};
            top: ${isMobile ? '0' : '-42px'};
            z-index: ${isMobile ? 1000 : -1};
            box-shadow: ${isMobile ? 'none' : '0 1px 4px 0 rgba(0, 0, 0, 0.1)'};
            width: ${isMobile ? 'auto' : '264px'};
          }
          .DayPicker-Months {
            width: ${isMobile ? '100%' : '100%'};
          }
          .input-from-to {
            width: ${isMobile ? '100%' : '264px'};
            padding-bottom: ${isMobile ? '370px' : 0};
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
          }
          .input-from-to__to,
          .input-from-to__from {
            margin-left: ${isMobile ? '6px' : '12px'};
          }
          .input-from-to__from .DayPickerInput-Overlay {
            margin-left: -34px;
          }
          .input-from-to__to .DayPickerInput-Overlay {
            box-shadow: 0;
            margin-left: -158px;
          }
        `}
      </style>
    </div>
  );
};

interface Props {
  classNames: any;
  selectedDay: any;
  isMobile: boolean;
  onClearDate: () => void;
}
const CustomOverlay: React.FC<Props> = ({
  onClearDate,
  selectedDay,
  classNames,
  children,
  isMobile,
  ...props
}) => {
  const { t } = useTranslation('student_portal');

  return (
    <div className={classNames.overlayWrapper} {...props}>
      <div className={classNames.overlay}>
        {children}
        <div className="reset">
          <Typography onClick={onClearDate} cursor="pointer" variant="body2">
            {t('select_bed.reset_date')}
          </Typography>
        </div>
        <style jsx>{`
          .reset {
            align-self: flex-end;
            margin-right: ${isMobile ? '8px' : '25px'};
            text-align: right;
            margin-bottom: 12px;
          }
        `}</style>
      </div>
    </div>
  );
};

export default DateRangePickerWrapper;
