import { UAContext } from '@quentin-sommer/react-useragent';
import moment from 'moment';
import React, { useContext, useRef, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import Typography from '~components/typography/typography';
import {
  TenancyEndDateTypeOption,
  TenancyLengthTypeOption,
  TenancyStartDateTypeOption,
} from '~graphql/__generated__/globalTypes';
import { useTranslation } from '~i18n';
import { IconDatepicker, IconRight } from '~svg-components';
import {
  getTenancyOptionRangeDayPickerEndDisabledDays,
  getTenancyOptionRangeDayPickerStartDisabledDays,
  isAfter,
  isSameDay,
  momentAddDays,
  momentSubtractDays,
  TenancyOption,
} from './tenancy-option-helpers';

interface TenancyOptionDateRangePickerProps {
  tenancyOption: TenancyOption;
  onFinishSelect: (arg: { startDate?: Date; endDate: Date }) => void;
}

const TenancyOptionDateRangePickerWrapper: React.FC<TenancyOptionDateRangePickerProps> = props => {
  const { tenancyOption, onFinishSelect } = props;
  const uaResults = useContext(UAContext).uaResults as UAResults;
  const isMobile = uaResults.mobile;
  const { t } = useTranslation('student_portal');
  const endDateRef = useRef<any>(null);
  const startDateRef = useRef<any>(null);
  const {
    startDate,
    endDate,
    startDateType,
    endDateType,
    tenancyLengthType,
    tenancyLength,
  } = tenancyOption;

  const parseStartDate = startDate ? moment(startDate).toDate() : moment().toDate();
  const parseEndDate = endDate ? moment(endDate).toDate() : moment().toDate();

  const isEQStartDateType = startDateType === TenancyStartDateTypeOption.EQ;
  const isEQEndDateType = endDateType === TenancyEndDateTypeOption.EQ;
  const isEQTenancyLengthType = tenancyLengthType === TenancyLengthTypeOption.EQ;
  const isLETenancyLengthType = tenancyLengthType === TenancyLengthTypeOption.LE;
  const [date, setDate] = useState({
    startDate: isEQStartDateType ? parseStartDate : undefined,
    endDate: isEQEndDateType ? parseEndDate : undefined,
  });

  const [key, setKey] = useState(+moment().toDate() + Math.random());
  const modifiers = { start: date.startDate, end: date.endDate };

  const focusStartDateInput = () => {
    startDateRef?.current?.getInput()?.focus();
    startDateRef?.current?.showDayPicker();
  };

  const focusEndDateInput = () => {
    endDateRef?.current?.getInput()?.focus();
    endDateRef?.current?.showDayPicker();
  };

  const getSelectedDays = () => {
    if (!date.endDate && date.startDate) {
      return date.startDate;
    }

    if (date.endDate && !date.startDate) {
      return date.endDate;
    }

    if (date.endDate && date.startDate) {
      return [{ from: date.startDate, to: date.endDate }];
    }

    return [];
  };

  const getStartAvailableDate = () => {
    if (isEQEndDateType && !date.startDate) {
      return isLETenancyLengthType
        ? momentSubtractDays(parseEndDate, tenancyLength)
        : parseStartDate;
    }

    return date.startDate || parseStartDate;
  };

  const getEndAvailableDate = () => {
    if (tenancyLength && date.startDate) {
      const endAvailableDate = momentAddDays(date.startDate, tenancyLength);
      if (!isAfter(parseEndDate, endAvailableDate)) {
        return isLETenancyLengthType
          ? momentAddDays(parseStartDate, tenancyLength)
          : parseStartDate;
      }

      return momentAddDays(date.startDate, tenancyLength);
    }

    return parseEndDate;
  };

  const handleDayMouseEnter = (day: any) => {
    setDate({
      ...date,
      endDate: day,
    });
  };

  const startDisableDays = getTenancyOptionRangeDayPickerStartDisabledDays(tenancyOption);

  const endDisabledDays = getTenancyOptionRangeDayPickerEndDisabledDays(
    tenancyOption,
    date?.startDate
  );

  const handleStartDateChange = (startDateValue: Date) => {
    if (isEQStartDateType && !isSameDay(parseStartDate, startDateValue)) {
      finishDateSelect(parseStartDate, startDateValue);
      return;
    }
    if (isEQEndDateType) {
      finishDateSelect(
        isSameDay(startDateValue, parseEndDate) ? undefined : startDateValue,
        parseEndDate
      );
      return;
    }
    if (isEQTenancyLengthType) {
      const endDateValue = momentAddDays(startDateValue, tenancyLength || 0);
      finishDateSelect(startDateValue, endDateValue);
      return;
    }
    if (startDateValue) {
      setDate({
        startDate: startDateValue,
        endDate: undefined,
      });
    }
  };

  const handleEndDateChange = (endDateValue: Date) => {
    if (isEQEndDateType) {
      finishDateSelect(date.startDate, parseEndDate);
      return;
    }

    if (isEQTenancyLengthType) {
      const calculateStartDate = momentSubtractDays(endDateValue, tenancyLength || 0);
      finishDateSelect(calculateStartDate, endDateValue);
      return;
    }

    if (endDateValue && date.startDate) {
      const isStartDateAfterEndDate = isAfter(date.startDate, endDateValue);

      if (isStartDateAfterEndDate) {
        finishDateSelect(endDateValue, date.startDate);
      } else {
        finishDateSelect(date.startDate, endDateValue);
      }
    }
  };

  const finishDateSelect = (startDateValue: Date | undefined, endDateValue: Date) => {
    const dateValue = {
      startDate: startDateValue,
      endDate: endDateValue,
    };

    setDate(dateValue);
    onFinishSelect(dateValue);
  };

  const handleClearDate = () => {
    setDate({
      startDate: isEQStartDateType ? parseStartDate : undefined,
      endDate: isEQEndDateType ? parseEndDate : undefined,
    });

    setKey(+moment().toDate() + Math.random());
    if (isEQStartDateType) {
      focusEndDateInput();
    } else {
      focusStartDateInput();
    }
  };

  return (
    <div className="input-from-to" key={key}>
      <IconDatepicker width="14.7" height="15.7" fill="var(--color-purple_4)" />
      <span className="input-from-to__from">
        <DayPickerInput
          value={date.startDate}
          placeholder={t('select_bed.tenancy_option.move_in_time')}
          ref={startDateRef}
          overlayComponent={(props: any) => (
            <CustomOverlay onClearDate={handleClearDate} isMobile={isMobile} {...props} />
          )}
          showOverlay
          inputProps={{
            readOnly: 'readonly',
          }}
          formatDate={MomentLocaleUtils.formatDate}
          parseDate={MomentLocaleUtils.parseDate}
          format="ll"
          dayPickerProps={{
            selectedDays: getSelectedDays(),
            disabledDays: startDisableDays,
            month: getStartAvailableDate(),
            toMonth: parseEndDate,
            fromMonth: parseStartDate,
            modifiers,
            numberOfMonths: 1,
            locale: moment.locale(),
            localeUtils: MomentLocaleUtils,
            onDayClick: () => {
              if (isEQEndDateType) {
                focusStartDateInput();
              } else {
                focusEndDateInput();
              }
            },
          }}
          onDayPickerHide={() => {
            if (date.startDate && isEQEndDateType) {
              focusStartDateInput();
            } else if (!date.startDate) {
              focusStartDateInput();
            } else {
              focusEndDateInput();
            }
          }}
          onDayChange={handleStartDateChange}
        />
      </span>
      <IconRight
        width="12"
        height="12"
        fill="var(--color-grey4c)"
        className="date-range-picker__icon-right"
      />
      <span className="input-from-to__to">
        <DayPickerInput
          ref={endDateRef}
          overlayComponent={(props: any) => (
            <CustomOverlay isMobile={isMobile} onClearDate={handleClearDate} {...props} />
          )}
          inputProps={{
            readOnly: 'readonly',
          }}
          value={date.endDate}
          placeholder={t('select_bed.tenancy_option.move_out_time')}
          onDayPickerHide={() => {
            if (document.activeElement === endDateRef.current.getInput() && !date.endDate) {
              focusEndDateInput();
            } else {
              focusStartDateInput();
            }
          }}
          formatDate={MomentLocaleUtils.formatDate}
          parseDate={MomentLocaleUtils.parseDate}
          format="ll"
          hideOnDayClick={false}
          dayPickerProps={{
            selectedDays: getSelectedDays(),
            disabledDays: endDisabledDays,
            modifiers,
            month: getEndAvailableDate(),
            toMonth: parseEndDate,
            fromMonth: parseStartDate,
            numberOfMonths: 1,
            locale: moment.locale(),
            localeUtils: MomentLocaleUtils,
            onDayMouseEnter: handleDayMouseEnter,
          }}
          onDayChange={handleEndDateChange}
        />
      </span>
      <style jsx global>
        {`
          .DayPickerInput {
            input {
              border: 0;
              font-size: 14px;
              line-height: 40px;
              width: ${isMobile ? '105px' : '100px'};
              color: var(--color-purple_4);
            }
          }
          .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
            background: var(--color-purple_1) !important;
            color: var(--color-purple_6);
          }
          .DayPicker-Day {
            font-size: ${isMobile ? '18px' : '14px'};
            border-radius: 0 !important;
          }
          .DayPicker-Month {
            margin: 0 !important;
          }
          .DayPicker {
            margin: 0 auto;
          }
          .DayPicker-NavButton {
            top: 0;
            right: 0.5em;
          }
          .DayPicker-Weekday {
            color: var(--color-purple_4);
          }
          .DayPicker-Day--start[aria-selected='true'] {
            background: var(--color-purple_6) !important;
            border-top-left-radius: 50% !important;
            color: var(--color-white) !important;
            border-bottom-left-radius: 50% !important;
          }
          .DayPicker-Day--end[aria-selected='true'] {
            background: var(--color-purple_6) !important;
            border-top-right-radius: 50% !important;
            color: var(--color-white) !important;
            border-bottom-right-radius: 50% !important;
          }
          .DayPicker-Caption > div {
            font-size: 14px;
            color: var(--color-purple_6);
            font-family: 'UniversalSans';
          }
          .DayPickerInput-Overlay {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: ${isMobile ? '0' : '42px'};
            top: ${isMobile ? '0' : '-42px'};
            z-index: ${isMobile ? 1000 : -1};
            box-shadow: ${isMobile ? 'none' : '0 1px 4px 0 rgba(0, 0, 0, 0.1)'};
            width: ${isMobile ? 'auto' : '264px'};
            border-radius: 14px;
            overflow: hidden;
          }
          .DayPicker-Months {
            width: ${isMobile ? '100%' : '100%'};
          }
          .input-from-to {
            width: ${isMobile ? '100%' : '264px'};
            padding-bottom: ${isMobile ? '370px' : 0};
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
          }
          .input-from-to__to,
          .input-from-to__from {
            margin-left: ${isMobile ? '6px' : '8px'};
          }
          .input-from-to__from .DayPickerInput-Overlay {
            margin-left: -34px;
          }
          .input-from-to__to .DayPickerInput-Overlay {
            box-shadow: 0;
            margin-left: -158px;
          }
        `}
      </style>
    </div>
  );
};

interface Props {
  classNames: any;
  selectedDay: any;
  isMobile: boolean;
  onClearDate: () => void;
}
const CustomOverlay: React.FC<Props> = ({
  onClearDate,
  selectedDay,
  classNames,
  children,
  isMobile,
  ...props
}) => {
  const { t } = useTranslation('student_portal');

  return (
    <div className={classNames.overlayWrapper} {...props}>
      <div className={classNames.overlay}>
        {children}
        <div className="reset">
          <Typography onClick={onClearDate} cursor="pointer" variant="body2">
            {t('select_bed.reset_date')}
          </Typography>
        </div>
        <style jsx>{`
          .reset {
            align-self: flex-end;
            margin-right: ${isMobile ? '8px' : '25px'};
            text-align: right;
            margin-bottom: 12px;
          }
        `}</style>
      </div>
    </div>
  );
};

export default TenancyOptionDateRangePickerWrapper;
