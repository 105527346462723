import Tippy from '@tippy.js/react';
import classnames from 'classnames';
import { useField } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import Select, { components, Props } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import Typography from '~components/typography/typography';
import { useTranslation } from '~i18n';
import { IconJumpDown, Info } from '~svg-components';
import stylesModule from './select.module.scss';

interface ISelectProps extends Props {
  creatable?: boolean;
  name: string;
  cacheOptions?: boolean;
  loadOptions?: (inputValue: string) => void;
  selectType?: 'custom' | 'async' | 'creatable' | 'asyncCreatable';
  placeholder?: string;
  label?: string;
  tooltips?: string;
  labelRight?: string | React.ReactNode;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  onSelect?: (option: any) => void;
}

const SelectComponent = React.forwardRef<
  any,
  ISelectProps & React.SelectHTMLAttributes<HTMLSelectElement>
>((props, ref) => {
  const {
    label,
    tooltips,
    labelRight,
    name,
    selectType,
    loadOptions,
    className,
    inputClassName,
    labelClassName,
    options,
    cacheOptions,
    isDisabled,
    styles,
    onSelect,
    ...others
  } = props;
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(name);
  const [arrowDown, setArrowDown] = useState(false);
  let selectRef = useRef<any>(null);
  const customStyles = {
    singleValue: (provided: any, state: any) => {
      return { ...provided, fontSize: 14, color: 'var(--color-purple_6)' };
    },
    container: (provided: any, state: { isFocused: any }) => {
      return { ...provided, marginTop: '5px', width: '100%', marginBottom: '4px' };
    },
    menu: (provided: any) => {
      return {
        ...provided,
        overflow: 'hidden',
      };
    },
    control: (provided: any, state: { isFocused: any }) => {
      let borderColor = state.isFocused ? 'var(--color-purple_6)' : 'var(--color-purple_4)';
      let boxShadow = state.isFocused
        ? '-2px -2px 4px rgba(64, 46, 82, 0.2), 2px 2px 4px rgba(64, 46, 82, 0.2)'
        : 'none';
      let hover = {
        ':hover': {
          backgroundColor: 'var(--color-gray_3)',
          borderColor: 'var(--color-purple_4)',
        },
      };

      let backgroundColor = isDisabled ? 'var(--color-purple_1)' : 'var(--color-white)';

      if (meta.error && meta.touched) {
        borderColor = 'var(--color-red_6)';
        boxShadow = '-2px -2px 4px rgba(140, 46, 41, 0.2), 2px 2px 4px rgba(140, 46, 41, 0.2)';
      }
      return { ...provided, borderColor, boxShadow, backgroundColor, ...hover };
    },
    input: (provided: any, state: { isFocused: any }) => {
      return {
        ...provided,
        fontSize: 14,
        lineHeight: '24px',
        color: 'var(--color-purple_6)',
        margin: 0,
        padding: '12px 0',
      };
    },
    indicatorSeparator: () => {},
    indicatorsContainer: (provided: any) => {
      return {
        ...provided,
        paddingRight: 4,
      };
    },
    valueContainer: (provided: any) => {
      return { ...provided, padding: '0 10px' };
    },
    placeholder: (provided: any) => {
      return {
        ...provided,
        color: 'var(--color-purple_3)',
        fontSize: 14,
      };
    },
    option: (provided: any, state: any) => ({
      ...provided,
      color: 'var(--color-purple_6)',
      fontSize: 14,
      fontWeight: state.isSelected ? 600 : 400,
      lineHeight: '24px',
      padding: 12,
      backgroundColor: 'var(--color-white)',
      ':hover': {
        backgroundColor: 'var(--color-purple_1)',
      },
      ':active': {
        backgroundColor: 'var(--color-purple_1)',
      },
    }),
    ...styles,
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <div
          className={classnames(stylesModule.dropdownIcon, {
            [stylesModule.dropdownIconFocus]: arrowDown,
          })}
        >
          <IconJumpDown width="8" height="6" fill="var(--color-purple_4)" />
        </div>
      </components.DropdownIndicator>
    );
  };

  useEffect(() => {
    if (process.browser && !isDisabled) {
      if (selectRef?.current.select.inputRef) {
        selectRef?.current?.select?.inputRef?.setAttribute('name', name);
      } else if (selectRef?.current?.select?.select?.select?.inputRef) {
        selectRef?.current.select.select.select.inputRef?.setAttribute('name', name);
      }
    }
  }, [name, isDisabled]);

  const selectCustomProps = {
    inputRef: ref,
    styles: customStyles,
    components: { DropdownIndicator },
    onMenuOpen: () => {
      setArrowDown(true);
    },
    onMenuClose: () => {
      setArrowDown(false);
    },
    isDisabled,
    theme: (theme: any) => ({
      ...theme,
      borderRadius: 14,
      colors: {
        ...theme.colors,
        primary25: 'var(--color-purple_1)',
        primary: 'var(--color-purple_6)',
        neutral90: 'var(--color-outline)',
      },
    }),
    ...others,
    name: field.name,
  };

  return (
    <div className={className}>
      <div className={stylesModule.labelContainer}>
        {label && (
          <label className={classnames(stylesModule.label, labelClassName)} htmlFor={name}>
            <span className={stylesModule.labelText}>{label}</span>
            {tooltips && (
              <Tippy
                content={
                  <Typography
                    variant="body3"
                    color="var(--color-white)"
                    style={{ wordBreak: 'break-word' }}
                  >
                    {tooltips}
                  </Typography>
                }
                animation="scale"
                className="base_tippy_pop"
              >
                <div style={{ width: 16, height: 16, marginLeft: 8 }}>
                  <Info
                    fill="var(--color-tundora)"
                    width={16}
                    height={16}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </Tippy>
            )}
          </label>
        )}
        <span className={stylesModule.labelRight}>{labelRight}</span>
      </div>
      <div className={stylesModule.inputContainer}>
        {selectType === 'asyncCreatable' && loadOptions && (
          // @ts-ignore
          <AsyncCreatableSelect
            cacheOptions
            loadOptions={loadOptions}
            onChange={option => {
              helpers.setValue(option);
            }}
            noOptionsMessage={() => t('form.select.no_result')}
            autoload={false}
            onBlur={() => {
              setTimeout(() => {
                helpers.setTouched(true);
              }, 100);
            }}
            value={field.value}
            {...selectCustomProps}
            ref={selectRef}
          />
        )}
        {selectType === 'async' && loadOptions && (
          // @ts-ignore
          <AsyncSelect
            {...selectCustomProps}
            cacheOptions
            loadOptions={loadOptions}
            onChange={option => {
              helpers.setValue(option);
            }}
            ref={selectRef}
            noOptionsMessage={() => t('form.select.no_result')}
            onBlur={() => {
              setTimeout(() => {
                helpers.setTouched(true);
              }, 100);
            }}
            value={field.value}
          />
        )}
        {selectType === 'creatable' && (
          // @ts-ignore
          <CreatableSelect
            options={options}
            {...selectCustomProps}
            onChange={(option): void => {
              helpers.setValue(option);
              if (onSelect) onSelect(option);
            }}
            ref={selectRef}
            noOptionsMessage={() => t('form.select.no_result')}
            onBlur={() => {
              setTimeout(() => {
                helpers.setTouched(true);
              }, 100);
            }}
            value={field.value}
          />
        )}
        {selectType === 'custom' && (
          // @ts-ignore
          <Select
            options={options}
            {...selectCustomProps}
            onChange={(option): void => {
              helpers.setValue(option);
              if (onSelect) onSelect(option);
            }}
            ref={selectRef}
            noOptionsMessage={() => t('form.select.no_result')}
            value={field.value}
            onBlur={() => {
              setTimeout(() => {
                helpers.setTouched(true);
              }, 100);
            }}
          />
        )}
      </div>
      {meta.touched && meta.error && (
        <Typography variant="body3" color="var(--color-red_6)">
          {meta.error}
        </Typography>
      )}
    </div>
  );
});
export default SelectComponent;
